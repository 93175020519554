import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import '../../assets/styles/components/capeb/Navbar.scss';

const Navbar = ({ project, onExportPdf, graph = null, className = '' }) => {
  return (
    <div className={`capeb-navbar ${className}`}>
      <div className="capeb-navbar__inner">
        <Container>
          <Row>
            <Col xs={12} sm>
              <div className="capeb-navbar__item">
                <Link className="capeb-navbar__link" to={`/projet/${project.slug}`}>&lt; Sommaire</Link>
              </div>
            </Col>
            
            {
              project.rubriques.filter(rub => rub.active)
              .map((rub, i) => {
                return (
                  <React.Fragment key={rub.name}>
                    {rub.subs.map((sub, j) => (
                      <Col key={'sub-'+j} xs={6} sm>
                        <div className={`capeb-navbar__item ${sub.active ? 'capeb-navbar__item--active' : ''}`}>
                          <span className="capeb-navbar__text">{sub.name}</span>
                          <ul className="capeb-navbar__submenu">
                            {sub.graphs.map((graph) => (
                              <li key={'graph-' + graph.id} className={`capeb-navbar__subitem ${graph.active ? 'capeb-navbar__subitem--active' : ''}`}>
                                <Link className={`capeb-navbar__link`} to={`/projet/${project.slug}/${graph.id}`}>{graph.name}</Link>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </Col>
                    ))}
                  </React.Fragment>
                );
              })
            }
            
            {(graph && ['capeb-analyse-departementale-dep'].indexOf(graph.type) >= 0) && (
              <Col xs={12} sm>
                <div className={`capeb-navbar__item`} onClick={onExportPdf}>
                  <span className="capeb-navbar__text">Télécharger les données</span>
                </div>
              </Col>
            )}
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Navbar;