import React from 'react';

import '../assets/styles/components/Loader.scss';

const Loader = ({ fixed=false }) => {
  return (
    <div className={`loader ${fixed ? 'loader--fixed' : ''}`}>
      <div className="loader__inner">
        <div className="loader__spinner" />
        <div className="loader__text">
          Chargement ...
        </div>
      </div>
    </div>
  );
};

export default Loader;