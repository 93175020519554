/* global $ */
import React, { useRef, useEffect, useState } from 'react';

const Map = ({ config, mapID, mapUrl, className = '' }) => {
    const mapWrapperRef = useRef(null);
    const [mapLoaded, setMapLoaded] = useState(false);

    // ---------------------------
    // check if map exists and init it
    // ---------------------------
    const createMap = (config, mapID) => {
        if (!$.mapael.maps || !$.mapael.maps[mapID]) {
            alert("Impossible d'afficher la carte sélectionnée.");
            return;
        }

        if (!mapWrapperRef.current) {
            return;
        }

        if (config.plots) {
            for (let key in config.plots) {
                if (!$.mapael.maps[mapID].elems[key]) {
                    delete config.plots[key];
                }
            }
        }

        const params = $.extend(
            true,
            {},
            {
                map: {
                    name: mapID,
                },
            },
            config,
        );
        try {
            $(mapWrapperRef.current).mapael(params);
        } catch (e) {
            console.log('Map.createMap error', e.message);
        }
    };

    // ---------------------------
    // If config change reInit map
    // ---------------------------
    useEffect(() => {
        if (!mapLoaded) {
            return;
        }

        if (!mapWrapperRef.current || !mapUrl) {
            return;
        }

        createMap(config, mapID);
    }, [config, mapLoaded]);

    // ---------------------------
    // Load map script if it changed and reInit map
    // ---------------------------
    useEffect(() => {
        setMapLoaded(false);
        const oldScript = document.getElementById('mapaelMap');
        if (oldScript) {
            oldScript.parentNode.removeChild(oldScript);
        }

        const nouveauScript = document.createElement('script');
        nouveauScript.setAttribute('type', 'text/javascript');
        nouveauScript.setAttribute('src', process.env.REACT_APP_URL + `js/maps/${mapUrl}.js`);
        nouveauScript.setAttribute('id', 'mapaelMap'); // Marquer le script comme lié à Mapael

        // Attacher un gestionnaire d'événements au chargement du script
        nouveauScript.onload = () => {
            // Configurer la carte après le chargement du script
            console.log('Map.loadSript success');
            setMapLoaded(true);
            // createMap(config, mapID);
        };

        // Ajouter le nouveau script au DOM
        document.body.appendChild(nouveauScript);
    }, [mapUrl]);

    return (
        <div className={`ra-map ${className}`}>
            <div className="ra-map__wrapper map-wrapper" ref={mapWrapperRef}>
                <div className="ra-map__map map"></div>
                <div className="ra-map__plots plotLegend"></div>
                <div className="ra-map__area areaLegend"></div>
            </div>
        </div>
    );
};

export default Map;
