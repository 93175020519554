import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import ReactHtmlParser from 'react-html-parser';

import Histogram from '../Histogram';
import { RenderedHistogram } from './QuestionAndHistogram';
import { useProjectContext } from '../../context/ProjectContext';

export const TableauAnalyseDepartementale = ({ total }) => {
  let { keys } = total;
  let colums = {};
  Object.values(keys).map(key => {
    Object.keys(key.values).map(i => colums[i] = key.values[i].label);
  });
  
  return (
    <table className="capeb-analyse-departementale__table">
      <thead>
        <tr>
          <th className="capeb-analyse-departementale__label"></th>
          {colums['selected'] != undefined && <th className="capeb-analyse-departementale__value">{ReactHtmlParser(colums['selected'].replace('||', '<br /><br />'))}</th>}
          {colums['taille'] != undefined && <th className="capeb-analyse-departementale__value">{ReactHtmlParser(colums['taille'].replace('||', '<br /><br />'))}</th>}
          {colums['all'] != undefined && <th className="capeb-analyse-departementale__value">{ReactHtmlParser(colums['all'].replace('||', '<br /><br />'))}</th>}
        </tr>
      </thead>
      <tbody>
        {Object.values(keys).map(key => (
          <tr key={key.id}>
            <td className="capeb-analyse-departementale__label">{key.question}</td>
            {colums['selected'] != undefined && <td className="capeb-analyse-departementale__value">{key.values['selected'] ? (Math.round(key.values['selected'].total * 100)/100).toLocaleString() : "--"}</td>}
            {colums['taille'] != undefined && <td className="capeb-analyse-departementale__value">{key.values['taille'] ? (Math.round(key.values['taille'].total * 100)/100).toLocaleString() : "--"}</td>}
            {colums['all'] != undefined && <td className="capeb-analyse-departementale__value">{key.values['all'] ? (Math.round(key.values['all'].total * 100)/100).toLocaleString() : "--"}</td>}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

const AnalyseDepartementale = ({ 
  graph,
  total,
  className = '',
  ...props
}) => {
  // ---------------------------------
  // ---------------------------------
  const { colors } = useProjectContext();
  
  const toLocaleString = (num) => num === false ? '-' : num.toLocaleString();
  
  // ---------------------------------
  // ---------------------------------
  const renderTableau = (total) => {
    
    if (!total) {
      return null;
    }
    
    if (!total.keys || !total.questions) {
      return null;
    }
    
    let { questions } = total;
    
    return (
      <div className={"capeb-analyse-departementale"}>
        <h3 className="capeb-analyse-departementale__title">1. Indicateurs d'activité et financiers</h3>
        
        <TableauAnalyseDepartementale total={total} />
        
        <h3 className="capeb-analyse-departementale__title">2. Questionnaire relatif à la Charte du Réseau</h3>
        {questions.map(question => (
          <div key={question.id} className="capeb-analyse-departementale__block">
            <h4 className="capeb-analyse-departementale__subtitle">{question.category}</h4>
            <RenderedHistogram items={question.items} question={question.category} />
          </div>
        ))}
      </div>
    ); 
  };
  
  return (
    <div className={`${className}`} {...props}>
      <Container>
        <Row>
          <Col>
            {renderTableau(total)}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AnalyseDepartementale;