import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { Container, Row, Col, Form } from 'react-bootstrap';

import '../../assets/styles/components/capeb/Filters.scss';

const buildYearFilter = (sectors, selected = '') => {
    const thematiqueOptions = sectors.map(({ name, code }) => {
        return {
            value: code,
            label: name,
        };
    });
    return {
        id: 'sector',
        title: 'Année',
        value: selected,
        options: [
            // { code: '', name: 'Choisir une année' },
            ...thematiqueOptions,
        ],
    };
};

const buildThematiqueFilter = (sectors, selected = '') => {
    const thematiqueOptions = sectors.map(({ name, code }) => {
        return {
            value: code,
            label: name,
        };
    });
    return {
        id: 'parent',
        title: 'Thématique',
        value: selected,
        options: [{ value: '', label: 'Ensemble des thématiques' }, ...thematiqueOptions],
    };
};

const buildSousThematiqueFilter = (sectors, thematiqueValue, selected = '') => {
    let sectorsOptions = [];
    const thematique = sectors.find((item) => item.code == thematiqueValue);
    if (thematique && thematique.subs) {
        sectorsOptions = thematique.subs.map(({ name, code }) => {
            return {
                value: code,
                label: name,
            };
        });
    }
    return {
        id: 'sector',
        title: 'Sous-Thématique',
        value: selected,
        options: [{ value: '', label: 'Ensemble des sous-thématiques' }, ...sectorsOptions],
    };
};

const buildQuestionFilter = (sectors, thematiqueValue, sousThematiqueValue, selected = '') => {
    let questionsOptions = [];
    const thematique = sectors.find((item) => item.code == thematiqueValue);
    if (thematique && thematique.subs) {
        const sousThematique = thematique.subs.find((item) => item.code == sousThematiqueValue);
        if (sousThematique && sousThematique.questions) {
            questionsOptions = sousThematique.questions.map(({ name, code }) => {
                return { value: code, label: name };
            });
        }
    }
    return {
        id: 'question',
        title: 'Question',
        value: selected,
        options: [{ value: '', label: 'Ensemble des questions' }, ...questionsOptions],
    };
};

const buildEnvironnementFilter = (sectors, selected = '') => {
    const thematiqueOptions = sectors.map(({ name, code }) => {
        return {
            value: code,
            label: name,
        };
    });
    return {
        id: 'sector',
        title: 'Sous-thématique',
        value: selected,
        options: [...thematiqueOptions],
    };
};

const buildTailleFilter = (sectors, environnementValue, selected = '') => {
    let sectorsOptions = [];
    const thematique = sectors.find((item) => item.code == environnementValue);
    if (thematique && thematique.subs) {
        sectorsOptions = thematique.subs.map(({ name, code }) => {
            return {
                value: code,
                label: name,
            };
        });
    }
    return {
        id: 'size',
        title: "Nombre d'adhérents",
        value: selected,
        options: [{ value: '', label: 'Choisir une taille' }, ...sectorsOptions],
    };
};

const Filters = ({
    graph,
    sectors,
    limitations,
    selectedLimitation,
    selectedSectors,
    handleSectorChange,
    handleLimitationChange,
    className = '',
}) => {
    const [sectorFilters, setSectorFilters] = useState([]);
    const [limitationFilter, setLimitationFilter] = useState(null);

    // Crée le filtre secteur
    const defineSectorFilters = (selectedObj) => {
        let newSectorFilters = [];
        const thematiqueCode = selectedObj.parent || '';
        const sousThematiqueCode = selectedObj.sector || '';
        const size = selectedObj.size || '';
        const sector = selectedObj.sector || '';
        switch (graph.type) {
            case 'capeb-tableau-de-bord-synthetique-reg':
                newSectorFilters.push(buildEnvironnementFilter(sectors, sector));
                break;
            case 'capeb-tableau-de-bord-synthetique-dep':
                newSectorFilters.push(buildEnvironnementFilter(sectors, sector));
                newSectorFilters.push(buildTailleFilter(sectors, sector, size));
                break;
            case 'capeb-taux-mise-oeuvre-global-dep':
            case 'capeb-taux-mise-oeuvre-global-reg':
                newSectorFilters.push(buildThematiqueFilter(sectors, thematiqueCode));
                newSectorFilters.push(buildSousThematiqueFilter(sectors, thematiqueCode, sousThematiqueCode));
                break;
            case 'capeb-mise-application-charte-reseau-dep':
            case 'capeb-mise-application-charte-reseau-reg':
                newSectorFilters.push(buildThematiqueFilter(sectors, thematiqueCode));
                newSectorFilters.push(buildSousThematiqueFilter(sectors, thematiqueCode, sousThematiqueCode));
                newSectorFilters.push(
                    buildQuestionFilter(sectors, thematiqueCode, sousThematiqueCode, selectedObj.question),
                );
                break;
            case 'capeb-analyse-departementale-dep':
                break;
            default:
                newSectorFilters.push(buildYearFilter(sectors, selectedObj.sector || ''));
                break;
        }

        setSectorFilters(newSectorFilters);
    };

    // Crée le filtre limitations
    const defineLimitationFilters = (selected = '') => {
        if (
            [
                'capeb-tableau-de-bord-synthetique-dep',
                'capeb-tableau-de-bord-synthetique-reg',
                'capeb-montant-cotisation-fixe-reg',
                'capeb-cotisations-syndicales-totales-reg',
                'capeb-resultat-net-reg',
                'capeb-subventions-contributions-exportation-produits-exportations-reg',
                'capeb-montant-cotisations-syndicales-totales-produits-exploitation-reg',
                'capeb-montant-cotisation-fixe-dep',
                'capeb-cotisations-syndicales-totales-dep',
                'capeb-montant-cotisations-syndicales-totales-produits-exploitation-dep',
                'resultat-net-dep',
                'capeb-montant-cotisation-variable-reg',
                'capeb-montant-cotisation-variable-dep',
                'charge-personnelle-charge-exploitation-dep',
                'capeb-charge-personnelle-charge-exploitation-reg',
            ].indexOf(graph.type) === -1
        ) {
            const options = limitations.map(({ name, code }) => {
                return {
                    value: code,
                    label: 'CAPEB ' + name,
                };
            });
            const newLimitationsFilter = {
                id: 'dep',
                title: 'CAPEB',
                value: selected,
                options: [
                    {
                        value: '',
                        label:
                            'Toutes les CAPEB ' + (graph.type.slice(-3) === 'reg' ? 'régionales' : 'départementales'),
                    },
                    ...options,
                ],
            };
            setLimitationFilter(newLimitationsFilter);
        }
    };

    useEffect(() => {
        defineSectorFilters(selectedSectors);
    }, [sectors, selectedSectors]);

    useEffect(() => {
        defineLimitationFilters(selectedLimitation);
    }, [limitations, selectedLimitation]);

    return (
        <div className={`capeb-filters ${className}`}>
            <Container>
                <Row>
                    {limitationFilter && (
                        <Col>
                            <div className={`capeb-filter capeb-filter--dep`}>
                                <Form.Group className="mb-3">
                                    <Form.Label>{limitationFilter.title}</Form.Label>
                                    <Select
                                        value={limitationFilter.options.find(
                                            (option) => option.value === limitationFilter.value,
                                        )}
                                        onChange={({ value }) => handleLimitationChange(value)}
                                        options={limitationFilter.options}
                                    />
                                </Form.Group>
                            </div>
                        </Col>
                    )}
                    {sectorFilters.map(({ id, title, value, options = [] }) => (
                        <Col key={id}>
                            <div className={`capeb-filter capeb-filter--${id}`}>
                                <Form.Group className="mb-3">
                                    <Form.Label>{title}</Form.Label>
                                    <Select
                                        value={options.find((option) => option.value === value)}
                                        onChange={({ value }) => handleSectorChange(id, value)}
                                        options={options}
                                    />
                                </Form.Group>
                            </div>
                        </Col>
                    ))}
                </Row>
            </Container>
        </div>
    );
};

export default Filters;
